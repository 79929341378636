import { BookingStep } from "../../@types";
import {
  selectCurrentRoomConfiguration,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { useOffers } from "../../http/offerApi";
import { useRoomTypes } from "../../http/roomApi";
import PageWithHeader from "../../pages/PageWithHeader";
import CenterWrapper from "../../ui/CenterWrapper";
import Prices from "../../ui/Prices";
import RatePlans from "../ratePlan/RatePlans";
import RoomDetailModal from "./RoomDetailModal";

const RoomDetailRatePlans = () => {
  const currentRoomConfiguration = useBookingStore(
    selectCurrentRoomConfiguration,
  );
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const setStep = useBookingStoreSetStep();
  const { offersMap } = useOffers();
  const { roomTypesMap } = useRoomTypes();

  if (!currentRoomConfiguration?.roomTypeId) {
    return null;
  }

  const room = roomTypesMap?.[currentRoomConfiguration.roomTypeId];
  const offer =
    offersMap?.[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ];

  if (!room || !offer) {
    return null;
  }

  return (
    <PageWithHeader>
      <CenterWrapper>
        <RoomDetailModal
          room={room}
          prices={
            <Prices
              basePrice={offer.cheapest_prices.base}
              minPrice={offer.cheapest_prices.min}
              minPricePerNight={offer.cheapest_prices.min_per_night}
              discount={offer.cheapest_prices.discount}
            />
          }
          open={true}
          onClose={() => {
            updateRoomConfiguration({ roomTypeId: null });
            setStep(BookingStep.Rooms);
          }}
          availableRooms={offer.available_rooms}
          footer={() => <RatePlans offer={offer} />}
        />
      </CenterWrapper>
    </PageWithHeader>
  );
};

export default RoomDetailRatePlans;
