import classNames from "classnames";
import { useState } from "react";
import { BookingStep } from "../../@types";
import {
  selectNights,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import {
  isModalActive,
  selectModalIdentifierValue,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { AutoOffer as AutoOfferType } from "../../http/offerApi";
import { useRoomTypes } from "../../http/roomApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import Headline from "../../ui/Headline";
import { Label } from "../../ui/Label";
import Prices from "../../ui/Prices";
import Plus from "../../ui/icon/plus.svg?react";
import { formatMoney } from "../../utils/number";
import { site } from "../../utils/site";
import RoomDetailModal from "../room/RoomDetailModal";
import styles from "./AutoOffer.module.css";
import { groupAutoOfferRooms, GroupedOfferRoom } from "./utils";

interface AutoOfferProps {
  index: number;
  autoOffer: AutoOfferType;
  className?: string;
}

const AutoOffer = ({ index, autoOffer, className }: AutoOfferProps) => {
  const setStep = useBookingStoreSetStep();
  const nights = useBookingStore(selectNights);
  const language = useBookingStore((state) => state.language);
  const setRoomConfigurations = useBookingStore(
    (state) => state.setRoomConfigurations,
  );
  const setAutoOfferIndex = useBookingStore((state) => state.setAutoOfferIndex);
  const i18n = translate(language);
  const { roomTypesMap } = useRoomTypes();
  const visualIndex = index + 1;
  const [showAllRooms, setShowAllRooms] = useState(false);

  const modalIdentifier = `AutoOffer_${index}`;
  const toggleRoomModal = useModalHistoryToggle(modalIdentifier);
  const showRoomModal = useModalHistoryStore(isModalActive(modalIdentifier));
  const modalRoomTypeId: string | undefined = useModalHistoryStore(
    selectModalIdentifierValue(modalIdentifier),
  );

  const offerOccupancyCounts = autoOffer.rooms.reduce(
    (sum, room) => {
      sum.adults += room.occupancy.adults;
      sum.children += room.occupancy.children?.length ?? 0;
      return sum;
    },
    {
      adults: 0,
      children: 0,
    },
  );

  const groupedRooms = groupAutoOfferRooms(autoOffer.rooms);

  const roomDetail =
    groupedRooms.find((x) => x.roomTypeId === modalRoomTypeId) ?? null;

  const bookAutoOffer = () => {
    setAutoOfferIndex(index);
    setRoomConfigurations(
      autoOffer.rooms.map((room, index) => ({
        roomIndex: index,
        roomTypeId: room.room_type_id,
        adults: room.occupancy.adults,
        children: room.occupancy.children ?? [],
      })),
    );
    setStep(BookingStep.RoomConfigurations);
  };

  const toggleRoomDetail = (room: GroupedOfferRoom | null) => {
    if (room?.roomTypeId === modalRoomTypeId) {
      return;
    }

    toggleRoomModal(!!room, room?.roomTypeId);
  };

  return (
    <Card
      key={index}
      className={classNames(className)}
      footer={
        <div className={styles.cardFooter}>
          <Button
            buttonProps={{
              title: i18n.autoOccupancy.bookOption(visualIndex),
              onClick: bookAutoOffer,
            }}
          >
            {i18n.autoOccupancy.bookOption(visualIndex)}
          </Button>
        </div>
      }
    >
      <Label className={styles.topLabel}>
        <Headline
          as="h2"
          size={4}
          title={i18n.autoOccupancy.option(visualIndex)}
        />
      </Label>
      <div className={styles.priceContainer}>
        {i18n.autoOccupancy.from}
        <Headline
          size={1}
          title={formatMoney(autoOffer.total_prices.min.amount, language, {
            currency: autoOffer.total_prices.min.currency,
          })}
        />
        {autoOffer.total_prices.base.amount >
          autoOffer.total_prices.min.amount && (
          <div className={styles.basePrice}>
            {`${i18n.autoOccupancy.from} ${formatMoney(
              autoOffer.total_prices.base.amount,
              language,
              {
                currency: autoOffer.total_prices.base.currency,
              },
            )}`}
          </div>
        )}
      </div>
      {nights && <div>{i18n.autoOccupancy.totalPriceNights(nights)}</div>}
      <Label className={styles.occupancyLabel}>
        {i18n.autoOccupancy.occupancy(
          offerOccupancyCounts.adults,
          offerOccupancyCounts.children,
          autoOffer.rooms.length,
          site.room_term,
        )}
      </Label>
      {groupedRooms
        .slice(0, !showAllRooms ? 2 : groupedRooms.length)
        .map((room, index) => (
          <Room
            key={index}
            room={room}
            onOpenDetail={(room) => toggleRoomDetail(room)}
          />
        ))}
      {groupedRooms.length > 2 && !showAllRooms && (
        <Button
          layout="link"
          buttonProps={{
            title: i18n.autoOccupancy.showAllRooms(site.room_term),
            onClick: () => setShowAllRooms(true),
            className: styles.showAllRoomsButton,
          }}
        >
          <span className={styles.plusIconWrapper}>
            <Plus className={styles.plusIcon} />
          </span>
          {i18n.autoOccupancy.showAllRooms(site.room_term)}
        </Button>
      )}
      <RoomDetailModal
        room={roomTypesMap?.[roomDetail?.roomTypeId ?? ""]}
        prices={
          roomDetail && (
            <Prices
              minPrice={{
                amount: roomDetail.price / roomDetail.count,
                currency: roomDetail.currency,
              }}
            />
          )
        }
        open={showRoomModal}
        onClose={() => toggleRoomDetail(null)}
        footer={
          <>
            <Headline
              size={2}
              title={i18n.rooms.roomOption(visualIndex, site.room_term)}
            />
            <div className={styles.roomDetailFooter}>
              {groupedRooms.map((room, index) => (
                <Room
                  key={index}
                  room={room}
                  onOpenDetail={(room) => toggleRoomDetail(room)}
                />
              ))}
              <Button
                buttonProps={{
                  title: i18n.autoOccupancy.bookOption(visualIndex),
                  onClick: bookAutoOffer,
                  className: styles.roomDetailFooterButton,
                }}
              >
                {i18n.autoOccupancy.bookOption(visualIndex)}
              </Button>
            </div>
          </>
        }
      />
    </Card>
  );
};

interface RoomProps {
  room: GroupedOfferRoom;
  onOpenDetail: (room: GroupedOfferRoom) => void;
}

const Room = ({ room, onOpenDetail }: RoomProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <div className={styles.room}>
      <Headline size={5} title={`${room.count}x`} className={styles.count} />
      <div className={styles.content}>
        <Button
          layout="link"
          buttonProps={{
            title: room.name,
            onClick: () => onOpenDetail(room),
            className: styles.title,
          }}
        >
          {room.name}
        </Button>
        <Headline
          size={4}
          title={i18n.autoOccupancy.roomOccupancy(room.adults, room.children)}
        />
      </div>
      <Headline
        size={5}
        title={formatMoney(room.price, language, {
          currency: room.currency,
        })}
        className={styles.price}
      />
    </div>
  );
};

export default AutoOffer;
