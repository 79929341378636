import { BookingStep, HeaderButtonCategory } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import useMediaQuery from "../../hooks/useMediaQuery";
import Form from "../../ui/Form";
import MobileModal from "../../ui/MobileModal";
import CalendarForm from "./CalendarForm";
import styles from "./CalendarModal.module.css";
import { FormDataCalendar } from "./utils";

const Calendar = () => {
  const setStep = useBookingStoreSetStep();
  const setArrivalAndDeparture = useBookingStore(
    (state) => state.setArrivalAndDeparture,
  );
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const activeHeaderButton = useBookingStore(
    (state) => state.activeHeaderButton,
  );
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const isDesktop = useMediaQuery("(min-width: 1126px)");

  return (
    <MobileModal
      align="right"
      size="large"
      isOpen={activeHeaderButton === HeaderButtonCategory.Calendar}
      onClose={() => setActiveHeaderButton(HeaderButtonCategory.None)}
    >
      <Form<FormDataCalendar>
        defaultValues={{
          arrival: arrival,
          departure: departure,
        }}
        className={styles.form}
        onSubmit={(data) => {
          setArrivalAndDeparture({
            arrival: data.arrival,
            departure: data.departure,
          });
          setStep(
            autoOccupancy
              ? BookingStep.AutoOccupancy
              : BookingStep.RoomConfigurations,
          );
        }}
      >
        <CalendarForm
          isDesktop={isDesktop}
          key={isDesktop ? "desktop" : "mobile"}
        />
      </Form>
    </MobileModal>
  );
};

export default Calendar;
