import { Field, Select as HeadlessSelect, Label } from "@headlessui/react";
import classNames from "classnames";
import { ChangeEventHandler, FocusEventHandler, forwardRef } from "react";
import styles from "./Select.module.css";
import ArrowDown from "./icon/arrow-down.svg?react";

interface Option {
  key: string;
  value: string;
  selected?: boolean;
}

interface SelectProps {
  options: Option[];
  name: string;
  label?: string;
  autocomplete?: boolean;
  autofocus?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  required?: boolean;
  size?: number;
  placeholder?: string;
  placeholderValue?: string | number | readonly string[];
  className?: string;
  classNameIcon?: string;
  value?: string | number | readonly string[];
  defaultValue?: string | number | readonly string[];
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      options,
      name,
      label,
      placeholder,
      placeholderValue = "",
      className,
      classNameIcon,
      value,
      defaultValue,
      onChange,
      onBlur,
      ...rest
    },
    ref,
  ) => {
    return (
      <Field className={styles.selectWrapper}>
        {label && <Label>{label}</Label>}
        <div className={styles.selectContainer}>
          <HeadlessSelect
            ref={ref}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            className={classNames(styles.select, className)}
            value={value}
            defaultValue={defaultValue}
            {...rest}
          >
            {placeholder && (
              <option
                disabled
                hidden
                className={styles.option}
                value={placeholderValue}
              >
                {placeholder}
              </option>
            )}
            {options.map((option: Option) => (
              <option
                className={styles.option}
                key={option.key}
                value={option.key}
              >
                {option.value}
              </option>
            ))}
          </HeadlessSelect>
          <ArrowDown
            className={classNames(styles.arrow, classNameIcon)}
            aria-hidden={true}
          />
        </div>
      </Field>
    );
  },
);

Select.displayName = "Select";

export default Select;
