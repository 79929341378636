import defaultTranslations from "../i18n/de";

export type I18n = typeof defaultTranslations;

export enum Language {
  German = "de",
  Italian = "it",
  English = "en",
  French = "fr",
}

export enum BookingStep {
  Init = "init",
  Start = "start",
  Occupancy = "occupancy",
  Calendar = "calendar",
  AutoOccupancy = "autoOccupancy",
  RoomConfigurations = "room-configurations",
  Rooms = "rooms",
  RatePlansAutoOffer = "rate-plans-auto-offer",
  RatePlans = "rate-plans",
  RatePlan = "rate-plan",
  Boards = "boards",
  Extra = "extra",
  Checkout = "checkout",
}

export enum HeaderButtonCategory {
  None = "none",
  Occupancy = "occupancy",
  Calendar = "calendar",
  Checkout = "checkout",
}

export enum GuestInteraction {
  Formal = "formal",
  Informal = "informal",
}

export enum RoomTerm {
  Room = "room",
  Apartment = "apartment",
  Suite = "suite",
}

export interface FetcherOptions {
  url: string;
  language?: Language;
}

export interface Prices {
  min: Price;
  min_per_night: Price;
  base: Price;
  discount: number;
}

export interface Price {
  currency: string;
  amount: number;
}

export interface Image {
  url: string;
  width: number;
  height: number;
}

export interface Photo {
  copyright: string;
  description: string;
  derivatives: Image[];
}

export type { AutoOccupancy, Occupancy } from "./occupancy";
export { OrderDirection } from "./order-direction";
export type { RoomConfiguration } from "./room-configuration";

export interface Colors {
  primary: string;
  primary_light: string;
}

export interface Site {
  id: string;
  property_id: number;
  property_name: string;
  phone_number: string;
  email: string;
  children_allowed: boolean;
  children_min_age: number;
  max_guests_per_room: number;
  logo: Image;
  background_image: {
    derivatives: Image[];
    copyright: string;
    description: {
      de: string;
      en: string;
      it: string;
      fr: string;
    };
  };
  colors: Colors;
  guest_interaction: GuestInteraction;
  room_term: RoomTerm;
  guest_field_settings: {
    gender: FieldSetting;
    phone_number: FieldSetting;
    street: FieldSetting;
    postal_code: FieldSetting;
    municipality: FieldSetting;
    country: FieldSetting;
    note: FieldSetting;
  };
}

enum FieldSetting {
  Disabled = "disabled",
  Optional = "optional",
  Required = "required",
}
