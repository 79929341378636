import {
  BookingStep,
  HeaderButtonCategory,
  Occupancy as OccupancyType,
} from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import Form from "../../ui/Form";
import MobileModal from "../../ui/MobileModal";
import { site } from "../../utils/site";
import OccupancyForm from "./OccupancyForm";
import styles from "./OccupancyModal.module.css";
import {
  FormDataOccupancies,
  getBaseFormDataOccupancy,
  getFormDataOccupancy,
} from "./utils";

const OccupancyModal = () => {
  const occupancies = useBookingStore((state) => state.occupancies);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const activeHeaderButton = useBookingStore(
    (state) => state.activeHeaderButton,
  );
  const setOccupancies = useBookingStore((state) => state.setOccupancies);
  const setAutoOccupancy = useBookingStore((state) => state.setAutoOccupancy);
  const setStep = useBookingStoreSetStep();

  return (
    <MobileModal
      isOpen={activeHeaderButton === HeaderButtonCategory.Occupancy}
      onClose={() => setActiveHeaderButton(HeaderButtonCategory.None)}
    >
      <Form<FormDataOccupancies>
        defaultValues={{
          useAutoOccupancy: !!autoOccupancy,
          occupancies: autoOccupancy
            ? [getBaseFormDataOccupancy(autoOccupancy, site)]
            : occupancies.map((occupancy: OccupancyType) =>
                getFormDataOccupancy(occupancy, site),
              ),
        }}
        className={styles.form}
        onSubmit={(data) => {
          if (data.useAutoOccupancy) {
            if (data.occupancies[0]) {
              setAutoOccupancy({
                adults: data.occupancies[0].adults,
                children: data.occupancies[0].children.map((x) => x.age),
              });
            }
          } else {
            setAutoOccupancy(null);
            setOccupancies(
              data.occupancies.map((occupancy) => ({
                adults: occupancy.adults,
                children: occupancy.children.map((x) => x.age),
                roomTypeId: occupancy.roomTypeId,
              })),
            );
          }
          setStep(BookingStep.Calendar);
        }}
      >
        <OccupancyForm />
      </Form>
    </MobileModal>
  );
};

export default OccupancyModal;
