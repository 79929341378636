export const maxRooms = 5;
export const maxAdultsAutoOccupancy = 99;
export const maxChildrenAutoOccupancy = 99;
export const maxChildrenAge = 17;

export const maxMonths = 24;
export const defaultMonthsCount = 3;
export const desktopNumberOfMonths = 2;
export const calendarMonthLoadingThreshold = 50;

export const staticRoomDetailPattern =
  /\/[a-zA-Z]{2}\/room-types\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})\/?.*/;
