import { useFormContext } from "react-hook-form";
import { AutoOfferRoom } from "../../http/offerApi";

interface FormDataRoomConfigurationOption {
  adults: number;
  children: number;
}

interface FormDataRoomConfiguration {
  roomTypeId: string | null;
  adults: number;
  children: number;
  childAgeIndices: number[];
  options?: Record<string, FormDataRoomConfigurationOption>;
}

export interface FormDataRoomConfigurations {
  roomConfigurations: FormDataRoomConfiguration[];
}

export const useIndividualAssignmentFormContext = () =>
  useFormContext<FormDataRoomConfigurations>();

export interface GroupedOfferRoom {
  count: number;
  roomTypeId: string;
  name: string;
  price: number;
  currency: string;
  adults: number;
  children: number;
  occupancyCount: number;
}

export const groupAutoOfferRooms = (rooms: AutoOfferRoom[]) => {
  const groupMap = rooms.reduce((roomsMap, room) => {
    const key = `${room.room_type_id}_${room.occupancy.adults}_${
      room.occupancy.children?.length ?? 0
    }_${room.cheapest_prices.min.currency}`;

    const existingGroup = roomsMap.get(key);
    if (existingGroup) {
      roomsMap.set(key, {
        count: existingGroup.count + 1,
        roomTypeId: room.room_type_id,
        name: room.name,
        price: existingGroup.price + room.cheapest_prices.min.amount,
        currency: room.cheapest_prices.min.currency,
        adults: existingGroup.adults,
        children: existingGroup.children,
        occupancyCount: existingGroup.occupancyCount,
      });
    } else {
      const adults = room.occupancy.adults;
      const children = room.occupancy.children?.length ?? 0;
      roomsMap.set(key, {
        count: 1,
        roomTypeId: room.room_type_id,
        name: room.name,
        price: room.cheapest_prices.min.amount,
        currency: room.cheapest_prices.min.currency,
        adults,
        children,
        occupancyCount: adults + children,
      });
    }
    return roomsMap;
  }, new Map<string, GroupedOfferRoom>());

  return [...groupMap.values()];
};
