import { useFieldArray } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import NumberInput from "../../ui/NumberInput";
import Select from "../../ui/Select";
import { maxChildrenAge } from "../../utils/constants";
import styles from "./OccupancyChildren.module.css";
import { useOccupancyFormContext } from "./utils";

interface OccupancyChildrenProps {
  occupancyIndex: number;
  maxChildrenCount: number;
  childrenMinAge: number;
}

const OccupancyChildren = ({
  occupancyIndex,
  maxChildrenCount,
  childrenMinAge,
}: OccupancyChildrenProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const { control } = useOccupancyFormContext();
  const {
    fields: childrenFields,
    append,
    remove,
  } = useFieldArray({
    name: `occupancies.${occupancyIndex}.children`,
    control,
  });

  return (
    <NumberInput
      title={i18n.occupancy.children}
      value={childrenFields.length}
      min={0}
      max={maxChildrenCount}
      description={i18n.occupancy.childrenInfo(childrenMinAge)}
      onChange={(children) =>
        children > childrenFields.length
          ? append({ age: -1 })
          : remove(childrenFields.length - 1)
      }
    >
      {childrenFields.length > 0 && (
        <div className={styles.childrenAges}>
          {childrenFields.map((childrenField, index) => (
            <ChildAge
              key={childrenField.id}
              occupancyIndex={occupancyIndex}
              index={index}
              childrenMinAge={childrenMinAge}
            />
          ))}
        </div>
      )}
    </NumberInput>
  );
};

interface ChildAgeProps {
  occupancyIndex: number;
  index: number;
  childrenMinAge: number;
}

const ChildAge = ({ occupancyIndex, index, childrenMinAge }: ChildAgeProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const { register } = useOccupancyFormContext();

  const registerProps = register(
    `occupancies.${occupancyIndex}.children.${index}.age`,
    {
      valueAsNumber: true,
      validate: (value) => value >= childrenMinAge && value <= maxChildrenAge,
    },
  );

  return (
    <div className={styles.childrenAgeWrapper}>
      <Select
        label={i18n.occupancy.childrenAge(index + 1)}
        className={styles.childrenAgeSelect}
        classNameIcon={styles.childrenAgeSelectIcon}
        placeholder={i18n.occupancy.selectAge}
        placeholderValue={-1}
        options={Array.from(
          { length: maxChildrenAge - childrenMinAge + 1 },
          (_, index) => ({
            key: `${childrenMinAge + index}`,
            value: `${childrenMinAge + index}`,
          }),
        )}
        {...registerProps}
      />
    </div>
  );
};

export default OccupancyChildren;
