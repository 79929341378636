import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Plus from "../../ui/icon/plus.svg?react";
import { maxRooms } from "../../utils/constants";
import { site } from "../../utils/site";
import { useOccupancyFormContext } from "./utils";

interface OccupancyFooterProps {
  roomCount: number;
  onAddRoom: () => void;
}

export const OccupancyFooter = ({
  roomCount,
  onAddRoom,
}: OccupancyFooterProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { formState, watch } = useOccupancyFormContext();

  const useAutoOccupancy = watch("useAutoOccupancy");
  const visualRoomCount = roomCount + 1;

  return (
    <>
      {roomCount < maxRooms && !useAutoOccupancy && (
        <Button
          buttonProps={{
            title: `${i18n.occupancy.room(site.room_term)} ${visualRoomCount}`,
            disabled: !formState.isValid,
            onClick: () => {
              onAddRoom();
            },
          }}
          layout="secondary"
          glyph={Plus}
        >
          {`${i18n.occupancy.room(site.room_term)} ${visualRoomCount}`}
        </Button>
      )}
      <Button
        buttonProps={{
          type: "submit",
          title: i18n.occupancy.apply,
          disabled: !formState.isValid,
        }}
        layout="primary"
      >
        {i18n.occupancy.apply}
      </Button>
    </>
  );
};
