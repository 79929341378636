import { useEffect } from "react";
import { Colors } from "../@types";

export const useColorTheme = (colors: Colors) => {
  useEffect(() => {
    const { style } = document.body;

    const map = {
      "--primary-color": colors.primary,
      "--primary-light-color": colors.primary_light,
    };

    Object.entries(map).forEach(([name, value]) =>
      style.setProperty(name, value),
    );

    return () => {
      Object.keys(map).forEach((name) => style.removeProperty(name));
    };
  }, [colors]);
};
