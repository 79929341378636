import { Locale, de, enGB, fr, it } from "date-fns/locale";
import { Language } from "../@types";

export const getLanguageByCode = (code: string): Language | null =>
  Object.values(Language).find((v) => v === (code as Language)) ?? null;

export const getLocaleByLanguage = (language: Language): Locale => {
  switch (language) {
    case Language.German:
      return de;
    case Language.Italian:
      return it;
    case Language.English:
      return enGB;
    case Language.French:
      return fr;
    default:
      // exhaustive check
      return language satisfies never;
  }
};
