import { useEffect } from "react";
import { BookingStep, HeaderButtonCategory } from "./@types";
import AutoOccupancy from "./domain/autoOccupancy/AutoOccupancy";
import Boards from "./domain/board/Boards";
import Calendar from "./domain/calendar/Calendar";
import Checkout from "./domain/checkout/Checkout";
import Extras from "./domain/extra/Extras";
import Init from "./domain/init/Init";
import Occupancy from "./domain/occupancy/Occupancy";
import RatePlanDetail from "./domain/ratePlan/RatePlanDetail";
import RatePlansAutoOffer from "./domain/ratePlan/RatePlansAutoOffer";
import RoomConfigurations from "./domain/room/RoomConfigurations";
import RoomDetailRatePlans from "./domain/room/RoomDetailRatePlans";
import Rooms from "./domain/room/Rooms";
import Start from "./domain/start/Start";
import {
  useBookingStore,
  useBookingStoreUpdateStateFromHistory,
} from "./hooks/useBookingStore";
import { useModalHistoryStore } from "./hooks/useModalHistory";

const Router = () => {
  const currentStep = useBookingStore((state) => state.step);
  const activeHeaderButton = useBookingStore(
    (state) => state.activeHeaderButton,
  );
  const updateStateFromHistory = useBookingStoreUpdateStateFromHistory();
  const updateModalHistoryStateFromHistory = useModalHistoryStore(
    (state) => state.updateStateFromHistory,
  );

  useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      updateStateFromHistory(e.state?.bookingStore ?? {});
      updateModalHistoryStateFromHistory(e.state?.modalHistoryIdentifiers);
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [updateModalHistoryStateFromHistory, updateStateFromHistory]);

  switch (currentStep) {
    case BookingStep.Start:
    case BookingStep.Occupancy:
    case BookingStep.Calendar:
    case BookingStep.Checkout:
      switch (activeHeaderButton) {
        case HeaderButtonCategory.Occupancy:
          return <Occupancy />;
        case HeaderButtonCategory.Calendar:
          return <Calendar />;
        case HeaderButtonCategory.Checkout:
          return <Checkout />;
        default:
          return <Start />;
      }
    case BookingStep.AutoOccupancy:
      return <AutoOccupancy />;
    case BookingStep.RoomConfigurations:
      return <RoomConfigurations />;
    case BookingStep.Rooms:
      return <Rooms />;
    case BookingStep.RatePlans:
      return <RoomDetailRatePlans />;
    case BookingStep.RatePlansAutoOffer:
      return <RatePlansAutoOffer />;
    case BookingStep.RatePlan:
      return <RatePlanDetail />;
    case BookingStep.Boards:
      return <Boards />;
    case BookingStep.Extra:
      return <Extras />;
    default: {
      return <Init />;
    }
  }
};

export default Router;
