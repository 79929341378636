import { ReactNode, useState } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { Room } from "../../http/roomApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import Grid from "../../ui/icon/grid.svg?react";
import Share from "../../ui/icon/share.svg?react";
import LightGallery from "../../ui/LightGallery";
import ResponsiveImage from "../../ui/ResponsiveImage";
import ShareButton from "../../ui/ShareButton";
import Slider from "../../ui/Slider";
import FavoredAmenities from "../amenity/FavoredAmenities";
import styles from "./RoomDetail.module.css";
import RoomInfos from "./RoomInfos";

interface RoomDetailProps {
  room: Room;
  prices?: ReactNode;
  footer?: ReactNode;
  shareView?: boolean;
}

const RoomDetail = ({
  room,
  prices,
  footer,
  shareView = false,
}: RoomDetailProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [showRoomPhotos, setShowRoomPhotos] = useState(false);

  return (
    <>
      <LightGallery
        photos={room.photos}
        isOpen={showRoomPhotos}
        onClose={() => setShowRoomPhotos(false)}
      />
      <CenterWrapper className={styles.roomDetails}>
        <div className={styles.roomName}>
          <Headline size={2} title={room.name} as="h1" />
          <ShareButton
            shareData={{
              title: room.name,
              text: room.description,
              url: `${location.origin}/${language}/room-types/${room.id}`,
            }}
            layout="icon"
            buttonProps={{
              "aria-label": i18n.general.share,
              className: styles.shareButton,
            }}
            glyph={Share}
          />
        </div>
        <RoomInfos room={room} prices={prices} />
        <FavoredAmenities room={room} shareView={shareView} />
      </CenterWrapper>
      <Slider
        className={styles.sliderContainer}
        dots={room.photos.length > 1}
        arrows={room.photos.length > 1}
        customControls={
          room.photos.length > 1 && (
            <Button
              layout="secondary"
              buttonProps={{
                title: i18n.rooms.showAllImages,
                onClick: () => setShowRoomPhotos(true),
                className: styles.lightboxOpener,
              }}
              glyph={Grid}
            >
              {i18n.rooms.showAllImages}
            </Button>
          )
        }
      >
        {room.photos.map((photo, index) => (
          <a
            className={styles.slide}
            aria-label={i18n.general.openLightbox}
            key={index}
            href={photo.derivatives[0]?.url}
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              setShowRoomPhotos(true);
            }}
          >
            <ResponsiveImage
              srcSet={photo.derivatives}
              alt={photo.description}
              sizes="100vw"
              className={styles.sliderImage}
              lazyLoad={true}
            />
          </a>
        ))}
      </Slider>
      {footer && (
        <CenterWrapper className={styles.footer}>{footer}</CenterWrapper>
      )}
    </>
  );
};

export default RoomDetail;
