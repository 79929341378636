import { CalendarDay } from "react-day-picker";
import { useBookingStore } from "../../hooks/useBookingStore";
import Date from "../../ui/Date";
import { formatMoney } from "../../utils/number";
import styles from "./CalendarDayContent.module.css";
import { useCalenderContext } from "./utils";

const getDate = (date: Date) => String(date.getDate());

interface CalendarDayContentProps {
  day: CalendarDay;
}

const CalendarDayContent = ({ day }: CalendarDayContentProps) => {
  const language = useBookingStore((state) => state.language);
  const calendarContext = useCalenderContext();

  const { arrivalAvailabilitiesMap } = calendarContext;
  const arrivalAvailabilityDay = arrivalAvailabilitiesMap.get(
    day.date.getTime(),
  );
  const price = arrivalAvailabilityDay?.price;

  return (
    <span className={styles.innerDay}>
      <Date className={styles.day} date={day.date} formatter={getDate} />
      <span className={styles.price}>
        {price ? formatMoney(price.amount, language, {}) : "-"}
      </span>
    </span>
  );
};

export default CalendarDayContent;
