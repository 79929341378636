import { useEffect } from "react";

interface UseIntersectionObserverOptions {
  root?: Element | null;
  target?: Element | null;
  onIntersect: () => void;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
}

const useIntersectionObserver = (options: UseIntersectionObserverOptions) => {
  const {
    root,
    target,
    onIntersect,
    threshold = 0,
    rootMargin = "0px",
    enabled = true,
  } = options;

  useEffect(() => {
    if (!target || !enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root ?? null,
        rootMargin,
        threshold,
      },
    );

    observer.observe(target);

    return () => {
      observer.disconnect();
    };
  }, [enabled, root, rootMargin, threshold, target, onIntersect]);
};

export default useIntersectionObserver;
