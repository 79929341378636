import classNames from "classnames";
import { PropsWithChildren } from "react";
import styles from "./Label.module.css";

type LabelProps = PropsWithChildren<{
  className?: string;
}>;

export const Label = ({ className, children }: LabelProps) => {
  return <div className={classNames(className, styles.label)}>{children}</div>;
};
