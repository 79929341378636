import { Price } from "../@types";
import { selectNights, useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import { formatMoney } from "../utils/number";
import { site } from "../utils/site";
import Headline from "./Headline";
import styles from "./Prices.module.css";

interface PricesProps {
  minPrice?: Price;
  minPricePerNight?: Price;
  basePrice?: Price;
  discount?: number;
}

const Prices = ({
  minPrice = { amount: 0, currency: "" },
  minPricePerNight = { amount: 0, currency: "" },
  basePrice = { amount: 0, currency: "" },
  discount = 0,
}: PricesProps) => {
  const nights = useBookingStore(selectNights);
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <div className={styles.prices}>
      {basePrice.amount > minPrice.amount && (
        <div className={styles.basePriceContainer}>
          {basePrice.amount > 0 && (
            <div className={styles.basePrice}>
              {formatMoney(basePrice.amount, language, {
                currency: basePrice.currency,
              })}
            </div>
          )}
          {discount > 0 && (
            <div className={styles.discount}>{i18n.rooms.save(discount)}</div>
          )}
        </div>
      )}
      {minPrice.amount > 0 && (
        <div className={styles.minPriceContainer}>
          {i18n.autoOccupancy.from}
          <Headline
            size={1}
            title={formatMoney(minPrice.amount, language, {
              currency: minPrice.currency,
            })}
          />
          {nights && (
            <div className={styles.nightsContainer}>
              {`/ ${i18n.rooms.nights(nights)}`}
            </div>
          )}
        </div>
      )}
      {minPricePerNight.amount > 0 && (
        <div className={styles.minPricePerNightContainer}>
          {formatMoney(minPricePerNight.amount, language, {
            currency: minPricePerNight.currency,
          })}
          {` / ${i18n.rooms.nightPerRoom(site.room_term)}`}
        </div>
      )}
    </div>
  );
};

export default Prices;
