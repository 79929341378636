import { differenceInDays } from "date-fns";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import { useCalendarFormContext } from "./utils";

interface CalendarFooterProps {
  arrival: Date;
  departure: Date;
}

const CalendarFooter = ({ arrival, departure }: CalendarFooterProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { formState } = useCalendarFormContext();

  const title = i18n.calendar.continueWithNights(
    differenceInDays(departure, arrival),
  );

  return (
    <Button
      buttonProps={{
        type: "submit",
        title,
        disabled: !formState.isValid,
      }}
      layout="primary"
    >
      {title}
    </Button>
  );
};

export default CalendarFooter;
