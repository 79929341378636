import { BookingStep, RoomConfiguration } from "../../@types";
import {
  isAutoOfferRoomConfiguration,
  selectCurrentRoomConfiguration,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { useAutoOffers, useOffers } from "../../http/offerApi";
import { useRatePlans } from "../../http/ratePlanApi";
import PageWithHeader from "../../pages/PageWithHeader";
import CenterWrapper from "../../ui/CenterWrapper";
import RatePlanDetailModal from "./RatePlanDetailModal";

const RatePlanDetail = () => {
  const isAutoOffer = useBookingStore(isAutoOfferRoomConfiguration);
  const currentRoomConfiguration = useBookingStore(
    selectCurrentRoomConfiguration,
  );

  if (!currentRoomConfiguration) {
    return null;
  }

  return isAutoOffer ? (
    <RatePlanDetailAutoOffers
      currentRoomConfiguration={currentRoomConfiguration}
    />
  ) : (
    <RatePlanDetailOffers currentRoomConfiguration={currentRoomConfiguration} />
  );
};

interface RatePlanDetailProps {
  currentRoomConfiguration: RoomConfiguration;
}

const RatePlanDetailOffers = ({
  currentRoomConfiguration,
}: RatePlanDetailProps) => {
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const setStep = useBookingStoreSetStep();
  const { offersMap } = useOffers();
  const { ratePlansMap } = useRatePlans();

  const offer =
    offersMap?.[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ];
  const ratePlan = ratePlansMap?.[currentRoomConfiguration.ratePlanId ?? ""];
  const rate = offer?.rates.find((x) => x.rate_plan_id === ratePlan?.id);

  if (!rate || !ratePlan) {
    return null;
  }

  return (
    <PageWithHeader>
      <CenterWrapper>
        <RatePlanDetailModal
          ratePlan={ratePlan}
          boardTypes={rate.board_types}
          open={true}
          onClose={() => {
            updateRoomConfiguration({ ratePlanId: null });
            setStep(BookingStep.RatePlans);
          }}
        />
      </CenterWrapper>
    </PageWithHeader>
  );
};

const RatePlanDetailAutoOffers = ({
  currentRoomConfiguration,
}: RatePlanDetailProps) => {
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const setStep = useBookingStoreSetStep();
  const { ratePlansMap } = useRatePlans();
  const { autoOffers } = useAutoOffers();

  const autoOfferIndex = useBookingStore((state) => state.autoOfferIndex);
  if (!autoOffers || !ratePlansMap || autoOfferIndex === null) {
    return null;
  }

  const ratePlan = ratePlansMap[currentRoomConfiguration.ratePlanId ?? ""];

  const rate = autoOffers[autoOfferIndex]?.roomsMap[
    `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
  ]?.rates.find((x) => x.rate_plan_id === currentRoomConfiguration.ratePlanId);

  if (!rate || !ratePlan) {
    return null;
  }

  return (
    <PageWithHeader>
      <CenterWrapper>
        <RatePlanDetailModal
          ratePlan={ratePlan}
          boardTypes={rate.board_types}
          open={true}
          onClose={() => {
            updateRoomConfiguration({ ratePlanId: null });
            setStep(BookingStep.RatePlansAutoOffer);
          }}
        />
      </CenterWrapper>
    </PageWithHeader>
  );
};

export default RatePlanDetail;
