import { useState } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Offer } from "../../http/offerApi";
import { useRatePlans } from "../../http/ratePlanApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Headline from "../../ui/Headline";
import Plus from "../../ui/icon/plus.svg?react";
import RatePlan from "./RatePlan";
import styles from "./RatePlans.module.css";

interface RatePlansProps {
  offer: Offer;
}

const RatePlans = ({ offer }: RatePlansProps) => {
  const { ratePlansMap } = useRatePlans();
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [showAllRatePlans, setShowAllRatePlans] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1126px)");

  return (
    <>
      <Headline title={i18n.ratePlans.selectRate} as="h2" size={2} />
      <div className={styles.ratePlans}>
        {offer.rates.map((rate, index) => {
          return (
            (showAllRatePlans || index === 0 || isDesktop) && (
              <RatePlan
                key={index}
                prices={rate.prices}
                ratePlan={ratePlansMap?.[rate.rate_plan_id]}
              />
            )
          );
        })}
      </div>
      {!showAllRatePlans && offer.rates.length > 1 && (
        <Button
          layout="link"
          buttonProps={{
            className: styles.showMoreButton,
            title: i18n.ratePlans.showMoreOptions,
            onClick: () => setShowAllRatePlans(true),
          }}
        >
          <span className={styles.showMoreButtonIconWrapper}>
            <Plus className={styles.showMoreButtonIcon} />
          </span>
          {i18n.ratePlans.showMoreOptions}
        </Button>
      )}
    </>
  );
};

export default RatePlans;
