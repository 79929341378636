import { useRoomTypes } from "../../http/roomApi";
import { HeaderPortal } from "../../layouts/Header";
import HeaderLogos from "../../layouts/HeaderLogos";
import CenterWrapper from "../../ui/CenterWrapper";
import RoomDetail from "./RoomDetail";
import styles from "./StaticRoomDetail.module.css";

interface StaticRoomDetailProps {
  roomId: string;
}

const StaticRoomDetail = ({ roomId }: StaticRoomDetailProps) => {
  const { roomTypesMap } = useRoomTypes();
  const room = roomTypesMap?.[roomId];

  if (!room) {
    return null;
  }

  return (
    <div className={styles.staticRoomDetail}>
      <HeaderPortal>
        <header className={styles.header}>
          <CenterWrapper className={styles.inner}>
            <HeaderLogos showMobileLogo={true} />
          </CenterWrapper>
        </header>
      </HeaderPortal>
      <RoomDetail room={room} shareView={true} />
    </div>
  );
};

export default StaticRoomDetail;
