import useSWR from "swr";
import { Photo } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { fetcher } from "./utils";

interface RoomTypeResponse {
  room_types: Room[];
  amenities: Amenity[];
  amenity_groups: AmenityGroup[];
}

export interface Room {
  id: string;
  name: string;
  description: string;
  occupancy: {
    min: number;
    max: number;
  };
  size?: number;
  amenity_ids: string[];
  photos: Photo[];
  amenities: Amenity[];
  amenityGroups: AmenityGroup[];
}

export interface Amenity {
  id: string;
  name: string;
  is_favored: boolean;
  group_id: string;
}

export interface AmenityGroup {
  id: string;
  name: string;
  amenities: Amenity[];
}

// TODO: replace with live url: `/properties/${propertyId}/room_types`
const roomTypesURL = "/room-types-de.json";

const useRoomTypesInternal = () => {
  const language = useBookingStore((state) => state.language);

  return useSWR({ url: roomTypesURL, language }, async (opts) => {
    const {
      room_types: roomTypes,
      amenities,
      amenity_groups: amenityGroups,
    } = await fetcher<RoomTypeResponse>(opts);

    amenityGroups.forEach(
      (ag) => (ag.amenities = amenities.filter((a) => a.group_id === ag.id)),
    );

    roomTypes.forEach((rt) => {
      const filteredAmenities = amenities.filter((a) =>
        rt.amenity_ids.includes(a.id),
      );
      rt.amenities = filteredAmenities;
      rt.amenityGroups = amenityGroups.reduce(
        (groups: AmenityGroup[], group) => {
          const groupAmenities = filteredAmenities.filter(
            (a) => a.group_id === group.id,
          );
          if (groupAmenities.length) {
            groups.push({
              ...group,
              amenities: groupAmenities,
            });
          }
          return groups;
        },
        [],
      );
    });

    return {
      roomTypes,
      roomTypesMap: Object.fromEntries(roomTypes.map((x) => [x.id, x])),
      amenities,
      amenityGroups,
    };
  });
};

export const useRoomTypes = () => {
  const { data, ...rest } = useRoomTypesInternal();

  return {
    ...rest,
    ...data,
  };
};
