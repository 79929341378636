import { Photo, Price } from "../../@types";
import { OfferBoard } from "../../http/offerApi";
import { BoardType, RatePlanBoard } from "../../http/ratePlanApi";

export interface Board {
  type: BoardType;
  description: string;
  surcharge: Price;
  photo?: Photo | undefined;
}

export const mergeBoards = (
  offerBoards: OfferBoard[],
  ratePlanBoards: RatePlanBoard[],
): Board[] => {
  const ratePlanBoardsMap = ratePlanBoards.reduce(
    (boardsMap: Record<string, RatePlanBoard>, x) => {
      boardsMap[x.type] = x;
      return boardsMap;
    },
    {},
  );

  return offerBoards.map((board) => {
    const ratePlanBoard = ratePlanBoardsMap[board.board_type];
    return {
      type: board.board_type,
      description: ratePlanBoard?.description ?? "",
      surcharge: board.surcharge,
      ...(ratePlanBoard?.photo && { photo: ratePlanBoard.photo }),
    };
  });
};
