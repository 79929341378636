import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import classNames from "classnames";
import { useState } from "react";
import { BookingStep } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import useMediaQuery from "../../hooks/useMediaQuery";
import { OfferBoard } from "../../http/offerApi";
import { Inclusive as InclusiveType, RatePlan } from "../../http/ratePlanApi";
import { translate } from "../../i18n";
import { BackButton } from "../../ui/BackButton";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import ArrowDown from "../../ui/icon/arrow-down.svg?react";
import Board from "../../ui/icon/board.svg?react";
import Grid from "../../ui/icon/grid.svg?react";
import InclusiveIcon from "../../ui/icon/inclusive.svg?react";
import LightGallery from "../../ui/LightGallery";
import { ReadMore } from "../../ui/ReadMore";
import ResponsiveImage from "../../ui/ResponsiveImage";
import Slider from "../../ui/Slider";
import styles from "./RatePlanDetailModal.module.css";

interface RatePlanDetailModalProps {
  ratePlan: RatePlan | null;
  boardTypes: OfferBoard[];
  open: boolean;
  onClose: () => void;
  autoOffer?: boolean;
}

const RatePlanDetailModal = ({
  ratePlan,
  boardTypes,
  open,
  onClose,
}: RatePlanDetailModalProps) => {
  const isDesktop = useMediaQuery("(min-width: 1126px)");
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [showRatePlanPhotos, setShowRatePlanPhotos] = useState(false);
  const [showAllReservationConditions, setShowAllReservationConditions] =
    useState(false);
  const setStep = useBookingStoreSetStep();
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );

  if (!ratePlan) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={styles.ratePlanDetailModal}
    >
      <DialogPanel className={styles.dialog}>
        <div className={styles.innerDialog}>
          <DialogTitle as="div" className={styles.header}>
            <BackButton onClick={onClose} />
          </DialogTitle>
          <div className={styles.details}>
            <div className={styles.titleWrapper}>
              <Headline
                size={2}
                as="h1"
                title={ratePlan.title}
                className={styles.title}
              />
            </div>
            <CenterWrapper>
              <Headline
                size={5}
                as="h2"
                title={i18n.ratePlans.general}
                className={styles.descriptionTitle}
              />
              <ReadMore text={ratePlan.description} />
            </CenterWrapper>
          </div>
          <LightGallery
            photos={ratePlan.photos}
            isOpen={showRatePlanPhotos}
            onClose={() => setShowRatePlanPhotos(false)}
          />
          <Slider
            className={styles.sliderContainer}
            dots={ratePlan.photos.length > 1}
            arrows={ratePlan.photos.length > 1}
            customControls={
              ratePlan.photos.length > 1 && (
                <Button
                  layout="secondary"
                  buttonProps={{
                    title: i18n.ratePlans.showAllImages,
                    onClick: () => setShowRatePlanPhotos(true),
                    className: styles.lightboxOpener,
                  }}
                  glyph={Grid}
                >
                  {i18n.ratePlans.showAllImages}
                </Button>
              )
            }
          >
            {ratePlan.photos.map((photo, index) => (
              <a
                className={styles.slide}
                aria-label={i18n.general.openLightbox}
                key={index}
                href={photo.derivatives[0]?.url}
                tabIndex={-1}
                onClick={(e) => {
                  e.preventDefault();
                  setShowRatePlanPhotos(true);
                }}
              >
                <ResponsiveImage
                  srcSet={photo.derivatives}
                  alt={photo.description}
                  sizes="100vw"
                  className={styles.sliderImage}
                  lazyLoad={true}
                />
              </a>
            ))}
          </Slider>
          <CenterWrapper className={styles.additionalDetails}>
            <div className={styles.block}>
              <Headline size={5} as="h2" title={i18n.ratePlans.inclusives} />
              <div
                className={classNames(
                  styles.additionalDetailsContent,
                  styles.inclusives,
                )}
              >
                {ratePlan.inclusives.map((inclusive) => (
                  <Inclusive key={inclusive.id} inclusive={inclusive} />
                ))}
              </div>
            </div>
            <div className={styles.block}>
              <h2>
                <Headline
                  size={5}
                  as="span"
                  title={i18n.ratePlans.boardsTitle}
                />
                <Headline
                  size={4}
                  as="span"
                  title={` ${i18n.ratePlans.boardsInfo}`}
                />
              </h2>
              <div
                className={classNames(
                  styles.additionalDetailsContent,
                  styles.boardTypes,
                )}
              >
                {boardTypes.map((boardType, index) => (
                  <div key={index} className={styles.boardType}>
                    <Board
                      className={styles.boardTypeIcon}
                      aria-hidden={true}
                    />
                    <div>
                      {i18n.boards[boardType.board_type]}
                      {boardType.surcharge.amount <= 0 &&
                        ` ${i18n.ratePlans.includedInRate}`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {ratePlan.reservation_conditions.length > 0 && (
              <div className={styles.block}>
                <Headline
                  size={5}
                  as="h2"
                  title={i18n.ratePlans.reservationConditions}
                />
                <div className={styles.additionalDetailsContent}>
                  <ul className={styles.reservationConditions}>
                    {ratePlan.reservation_conditions.map((condition, index) => {
                      return (
                        (showAllReservationConditions ||
                          index < 4 ||
                          isDesktop) && <li key={index}>{condition}</li>
                      );
                    })}
                  </ul>
                </div>
                {!showAllReservationConditions &&
                  ratePlan.reservation_conditions.length > 4 && (
                    <Button
                      layout="link"
                      buttonProps={{
                        className: styles.showMoreReservationConditionsButton,
                        title: i18n.ratePlans.readMore,
                        onClick: () => setShowAllReservationConditions(true),
                      }}
                    >
                      {i18n.ratePlans.readMore}
                    </Button>
                  )}
              </div>
            )}
          </CenterWrapper>
        </div>
        <div className={styles.buttonWrapper}>
          <CenterWrapper className={styles.buttonCenterWrapper}>
            <Button
              layout="primary"
              buttonProps={{
                title: i18n.ratePlans.selectAndContinue,
                className: styles.continueButton,
                onClick: () => {
                  updateRoomConfiguration({ ratePlanId: ratePlan.id });
                  setStep(BookingStep.Boards);
                },
              }}
            >
              {i18n.ratePlans.selectAndContinue}
            </Button>
          </CenterWrapper>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

interface InclusiveProps {
  inclusive: InclusiveType;
}

const Inclusive = ({ inclusive }: InclusiveProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [showInclusivePhotos, setShowInclusivePhotos] = useState(false);

  return (
    <Disclosure>
      {({ open }) => (
        <div className={styles.inclusive}>
          <DisclosureButton className={styles.inclusiveButton}>
            <span className={styles.inclusiveTitleWrapper}>
              {inclusive.thumbnail ? (
                <img
                  src={inclusive.thumbnail.url}
                  width={inclusive.thumbnail.width}
                  height={inclusive.thumbnail.height}
                  alt={inclusive.title}
                  className={styles.inclusivePhoto}
                />
              ) : (
                <InclusiveIcon
                  className={styles.inclusivePhoto}
                  aria-hidden={true}
                />
              )}
              {inclusive.title}
            </span>
            <ArrowDown
              className={classNames(styles.inclusiveArrow, {
                [styles.inclusiveArrowOpen]: open,
              })}
            />
          </DisclosureButton>
          <DisclosurePanel className={styles.inclusiveContent}>
            {inclusive.photos.length > 0 && (
              <>
                <LightGallery
                  photos={inclusive.photos}
                  isOpen={showInclusivePhotos}
                  onClose={() => setShowInclusivePhotos(false)}
                />
                <Slider
                  className={styles.inclusiveSliderContainer}
                  dots={inclusive.photos.length > 1}
                >
                  {inclusive.photos.map((photo, index) => (
                    <a
                      className={styles.inclusiveSlide}
                      aria-label={i18n.general.openLightbox}
                      key={index}
                      href={photo.derivatives[0]?.url}
                      tabIndex={-1}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowInclusivePhotos(true);
                      }}
                    >
                      <ResponsiveImage
                        srcSet={photo.derivatives}
                        alt={photo.description}
                        sizes="100vw"
                        className={styles.inclusiveSliderImage}
                        lazyLoad={true}
                      />
                    </a>
                  ))}
                </Slider>
              </>
            )}
            {inclusive.description}
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  );
};

export default RatePlanDetailModal;
