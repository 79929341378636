import classNames from "classnames";
import { PropsWithChildren } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "./Button";
import Headline from "./Headline";
import styles from "./NumberInput.module.css";
import Minus from "./icon/minus.svg?react";
import Plus from "./icon/plus.svg?react";

type NumberInputProps = PropsWithChildren<{
  title: string;
  value: number;
  min: number;
  max: number;
  description?: string;
  className?: string;
  disabled?: boolean;
  onChange: (number: number) => void;
}>;

const NumberInput = ({
  title,
  value,
  min,
  max,
  description,
  className,
  onChange,
  children,
  disabled,
}: NumberInputProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <div className={classNames(styles.numberInput, className)}>
      <div className={styles.text}>
        <Headline size={2} title={title} />
        {description}
      </div>
      <div className={styles.input}>
        <Button
          layout="link"
          buttonProps={{
            onClick: () => (value > min ? onChange(value - 1) : ""),
            className: styles.button,
            "aria-label": i18n.general.less,
            disabled: value <= min || disabled,
          }}
        >
          <Minus className={styles.icon} aria-hidden={true} />
        </Button>
        <input
          type="text"
          inputMode="numeric"
          disabled
          value={value}
          className={styles.value}
        />
        <Button
          layout="link"
          buttonProps={{
            onClick: () => (value < max ? onChange(value + 1) : ""),
            className: styles.button,
            "aria-label": i18n.general.more,
            disabled: value >= max || disabled,
          }}
        >
          <Plus className={styles.icon} aria-hidden={true} />
        </Button>
      </div>
      {children && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default NumberInput;
