import { useState } from "react";
import { selectNights, useBookingStore } from "../../hooks/useBookingStore";
import { usePortalPrices } from "../../http/offerApi";
import { translate } from "../../i18n";
import Headline from "../../ui/Headline";
import Switch from "../../ui/Switch";
import { formatMoney } from "../../utils/number";
import styles from "./PortalPrices.module.css";

const PortalPrices = () => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const nights = useBookingStore(selectNights);
  const { portalPrices } = usePortalPrices();
  const [showPortalPrices, setShowPortalPrices] = useState(true);

  return (
    <div className={styles.portalPrices}>
      <div className={styles.title}>
        {i18n.rooms.showPortalPrices}
        <Switch
          checked={showPortalPrices}
          onChange={() => setShowPortalPrices(!showPortalPrices)}
        />
      </div>
      {showPortalPrices && (
        <div className={styles.portals}>
          {portalPrices?.map((portalPrice, index) => (
            <div key={index} className={styles.portal}>
              <div className={styles.iconWrapper}>
                <img
                  className={styles.icon}
                  alt={portalPrice.name}
                  title={portalPrice.name}
                  src={portalPrice.icon.url}
                  width={portalPrice.icon.width}
                  height={portalPrice.icon.height}
                />
              </div>
              {portalPrice.name}
              {nights && (
                <Headline
                  size={5}
                  className={styles.price}
                  title={`${formatMoney(portalPrice.price.amount, language, {
                    currency: portalPrice.price.currency,
                  })}\xa0/ ${i18n.rooms.nights(nights)}`}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PortalPrices;
