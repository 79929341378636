import useSWR from "swr";
import { Image, Price, Prices } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { BoardType } from "./ratePlanApi";
import { fetcher } from "./utils";

export interface Offers {
  offers: Offer[];
}

export interface Offer {
  room_type_id: string;
  name: string;
  room_index: number;
  available_rooms: number | null;
  cheapest_prices: Prices;
  rates: Rates[];
}

export interface OfferBoard {
  board_type: BoardType;
  surcharge: Price;
}

export interface Rates {
  rate_plan_id: string;
  prices: Prices;
  board_types: OfferBoard[];
}

// TODO: replace with live url: `/properties/${propertyId}/offers`
const offersURL = "/offers.json";

export const useOffers = () => {
  const { data, ...rest } = useSWR({ url: offersURL }, async (opts) => {
    const { offers } = await fetcher<Offers>(opts);

    return {
      offers,
      offersMap: Object.fromEntries(
        offers.map((x) => [`${x.room_index}_${x.room_type_id}`, x]),
      ),
    };
  });

  return {
    ...rest,
    ...data,
  };
};

export interface AutoOffers {
  auto_offers: AutoOffer[];
}

export interface AutoOffer {
  total_prices: Prices;
  rooms: AutoOfferRoom[];
  roomsMap: Record<string, AutoOfferRoom>;
}

export interface AutoOfferRoom {
  room_type_id: string;
  name: string;
  room_index: number;
  available_rooms: number | null;
  cheapest_prices: Prices;
  occupancy: {
    adults: number;
    children?: number[];
  };
  rates: Rates[];
}

// TODO: replace with live url: `/properties/${propertyId}/auto_offers`
const autoOffersURL = "/auto-offers-de.json";

export const useAutoOffers = () => {
  const language = useBookingStore((state) => state.language);

  const { data, ...rest } = useSWR(
    { url: autoOffersURL, language },
    async (opts) => {
      const { auto_offers: autoOffers } = await fetcher<AutoOffers>(opts);

      autoOffers.forEach((ao) => {
        ao.roomsMap = Object.fromEntries(
          ao.rooms.map((x) => [`${x.room_index}_${x.room_type_id}`]),
        );
      });

      return {
        autoOffers,
      };
    },
  );

  return {
    ...rest,
    ...data,
  };
};

export interface PortalPrices {
  portal_prices: PortalPrice[];
}

export interface PortalPrice {
  name: string;
  room_index?: number;
  icon: Image;
  price: Price;
}

// TODO: replace with live url: `/properties/${propertyId}/portal_prices`
const portalPricesURL = "/portal-prices.json";

export const usePortalPrices = () => {
  const { data, ...rest } = useSWR(
    { url: portalPricesURL },
    async (opts) => (await fetcher<PortalPrices>(opts)).portal_prices,
  );

  return {
    ...rest,
    portalPrices: data,
  };
};
