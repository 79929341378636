import { Image } from "../@types";

interface ImagesByOrientation {
  landscape: Image[];
  portrait: Image[];
}

export const getImagesByOrientation = (
  derivatives: Image[],
): ImagesByOrientation => ({
  landscape: derivatives.filter(({ width, height }) => width > height),
  portrait: derivatives.filter(({ width, height }) => width <= height),
});
