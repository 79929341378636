import { useEffect } from "react";
import { BookingStep, RoomConfiguration } from "../../@types";
import {
  areAllRoomConfigurationsValid,
  isAutoOfferRoomConfiguration,
  isRoomConfigurationConfigurable,
  isRoomConfigurationValid,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { useRatePlans } from "../../http/ratePlanApi";
import { useRoomTypes } from "../../http/roomApi";
import { translate } from "../../i18n";
import PageWithHeader from "../../pages/PageWithHeader";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import Edit from "../../ui/icon/edit.svg?react";
import { site } from "../../utils/site";
import styles from "./RoomConfigurations.module.css";

const RoomConfigurations = () => {
  const language = useBookingStore((state) => state.language);
  const setStep = useBookingStoreSetStep();
  const roomConfigurations = useBookingStore(
    (state) => state.roomConfigurations,
  );
  const allRoomConfigurationsValid = useBookingStore(
    areAllRoomConfigurationsValid,
  );
  const setCurrentRoomConfigurationIndex = useBookingStore(
    (state) => state.setCurrentRoomConfigurationIndex,
  );
  const i18n = translate(language);

  useEffect(() => {
    if (allRoomConfigurationsValid) {
      setStep(BookingStep.Extra, true);
      return;
    }

    if (roomConfigurations.length === 1) {
      setCurrentRoomConfigurationIndex(0);
      setStep(BookingStep.Rooms, true);
    }
  }, [
    allRoomConfigurationsValid,
    roomConfigurations.length,
    setCurrentRoomConfigurationIndex,
    setStep,
  ]);

  return (
    <PageWithHeader
      header={
        <Headline
          as="h1"
          size={2}
          title={i18n.roomConfigurations.selectRoom[site.guest_interaction](
            site.room_term,
          )}
        />
      }
    >
      <CenterWrapper>
        <div className={styles.roomConfigurations}>
          {roomConfigurations.map((roomConfiguration) => (
            <RoomConfigurationComponent
              key={roomConfiguration.roomIndex}
              roomConfiguration={roomConfiguration}
            />
          ))}
        </div>
      </CenterWrapper>
    </PageWithHeader>
  );
};

interface RoomConfigurationProps {
  roomConfiguration: RoomConfiguration;
}
const RoomConfigurationComponent = ({
  roomConfiguration,
}: RoomConfigurationProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const {
    adults = 0,
    children = [],
    roomTypeId,
    roomIndex,
    ratePlanId,
    boardType,
  } = roomConfiguration;
  const { roomTypesMap } = useRoomTypes();
  const { ratePlansMap } = useRatePlans();
  const isConfigurable = useBookingStore(
    isRoomConfigurationConfigurable(roomConfiguration),
  );
  const setCurrentRoomConfigurationIndex = useBookingStore(
    (state) => state.setCurrentRoomConfigurationIndex,
  );
  const isAutoOffer = useBookingStore(isAutoOfferRoomConfiguration);
  const setStep = useBookingStoreSetStep();
  const configIsValid = isRoomConfigurationValid(roomConfiguration);
  const room = roomTypesMap?.[roomTypeId ?? ""];
  const ratePlan = ratePlansMap?.[ratePlanId ?? ""];
  const visualIndex = roomIndex + 1;

  return (
    <Card
      footer={
        !configIsValid && (
          <Button
            buttonProps={{
              disabled: !isConfigurable,
              title: i18n.roomConfigurations.continue,
              onClick: () => {
                setCurrentRoomConfigurationIndex(roomIndex);
                setStep(
                  isAutoOffer
                    ? BookingStep.RatePlansAutoOffer
                    : BookingStep.Rooms,
                );
              },
            }}
          >
            {i18n.roomConfigurations.continue}
          </Button>
        )
      }
    >
      <div className={styles.title}>
        <div>
          <Headline
            as="span"
            size={3}
            title={i18n.roomConfigurations.roomIndex(
              site.room_term,
              visualIndex,
            )}
          />
          {room && !configIsValid && (
            <>
              <Headline as="span" size={3} title={": "} />
              <span className={styles.roomName}>{room.name}</span>
            </>
          )}
        </div>
        {configIsValid && (
          <Button
            layout="icon"
            buttonProps={{
              onClick: () => {
                setCurrentRoomConfigurationIndex(roomIndex);
                setStep(BookingStep.Rooms);
              },
              "aria-label": i18n.general.edit,
            }}
            glyph={Edit}
          />
        )}
      </div>
      {configIsValid && (
        <div>
          {`${i18n.roomConfigurations.room(site.room_term)}: ${room?.name}`}
        </div>
      )}
      <div>
        {configIsValid && `${i18n.roomConfigurations.guests}: `}
        {adults && i18n.roomConfigurations.adults(adults)}
        {children.length > 0 &&
          `, ${i18n.roomConfigurations.children(children.length)}`}
      </div>
      {ratePlan && (
        <div>
          {configIsValid && `${i18n.roomConfigurations.ratePlan}: `}
          {ratePlan.title}
        </div>
      )}
      {boardType && (
        <div>
          {configIsValid && `${i18n.roomConfigurations.mealType}: `}
          {i18n.boards[boardType]}
        </div>
      )}
    </Card>
  );
};

export default RoomConfigurations;
