import { Language } from "../@types";

export const formatMoney = (
  number: number,
  language: Language,
  options?: Intl.NumberFormatOptions,
) =>
  new Intl.NumberFormat(language, {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
    ...options,
  }).format(number / 100);
