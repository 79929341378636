import { I18n, Language } from "../@types";
import de from "./de";
import en from "./en";
import fr from "./fr";
import it from "./it";

export const translate = (language: Language): I18n =>
  ({
    [Language.English]: en,
    [Language.German]: de,
    [Language.Italian]: it,
    [Language.French]: fr,
  })[language];
