import { format, parse } from "date-fns";
import { Language } from "../@types";

const defaultFormatString = "yyyy-MM-dd";

export const parseDate = (
  dateString: string,
  formatString: string = defaultFormatString,
): Date => {
  return parse(dateString, formatString, new Date());
};

export const parseDateNullable = (
  dateString: string,
  formatString: string = defaultFormatString,
): Date | null => {
  const date = parseDate(dateString, formatString);
  if (isNaN(date.getTime())) {
    return null;
  }

  return date;
};

export const formatDate = (
  date: Date,
  formatString: string = defaultFormatString,
): string => {
  return format(date, formatString);
};

export const formatDateNullable = (
  date: Date | null,
  formatString: string = defaultFormatString,
): string | null => {
  if (!date) {
    return null;
  }

  try {
    return formatDate(date, formatString);
  } catch {
    return null;
  }
};

export const formatIntlDate =
  (language: Language, options?: Intl.DateTimeFormatOptions) => (date: Date) =>
    new Intl.DateTimeFormat(language, {
      weekday: "short",
      day: "2-digit",
      month: "short",
      ...options,
    }).format(date);
