import { useFormContext } from "react-hook-form";
import { AutoOccupancy, Occupancy, Site } from "../../@types";
import { maxChildrenAge } from "../../utils/constants";

interface FormDataChildrenAge {
  age: number;
}

export interface FormDataOccupancy {
  adults: number;
  children: FormDataChildrenAge[];
  roomTypeId: string | null;
}

export interface FormDataOccupancies {
  useAutoOccupancy: boolean;
  occupancies: FormDataOccupancy[];
}

export const getFormDataOccupancy = (
  occupancy: Occupancy,
  site: Site,
): FormDataOccupancy => {
  return {
    ...getBaseFormDataOccupancy(occupancy, site),
    roomTypeId: occupancy.roomTypeId,
  };
};

export const getBaseFormDataOccupancy = (
  occupancy: Occupancy | AutoOccupancy,
  site: Site,
): FormDataOccupancy => {
  return {
    adults: occupancy.adults,
    children: occupancy.children.map((age) => ({
      age: age < site.children_min_age || age > maxChildrenAge ? -1 : age,
    })),
    roomTypeId: null,
  };
};

export const useOccupancyFormContext = () =>
  useFormContext<FormDataOccupancies>();
