import { useBookingStore } from "../../hooks/useBookingStore";
import {
  isModalActive,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { Room } from "../../http/roomApi";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Headline from "../../ui/Headline";
import { site } from "../../utils/site";
import AmenitiesModal from "./AmenitiesModal";
import AmenityIcons from "./AmenityIcons";
import styles from "./FavoredAmenities.module.css";

interface FavoredAmenitiesProps {
  room: Room;
  shareView?: boolean;
}

const FavoredAmenities = ({
  room,
  shareView = false,
}: FavoredAmenitiesProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const modalIdentifier = `FavoredAmenities_${room.id}`;
  const toggleAmenitiesModal = useModalHistoryToggle(modalIdentifier);
  const showAmenitiesModal = useModalHistoryStore(
    isModalActive(modalIdentifier),
  );

  if (!room.amenities.length) {
    return null;
  }

  return (
    <div className={styles.favoredAmenities}>
      <Headline size={5} title={i18n.rooms.amenities(site.room_term)} />
      <ul className={styles.amenities}>
        {room.amenities
          .filter((amenity) => amenity.is_favored)
          .map((amenity) => {
            const AmenityIcon = AmenityIcons[amenity.id];
            return (
              <li key={amenity.id} className={styles.amenity}>
                {AmenityIcon && (
                  <AmenityIcon
                    aria-hidden={true}
                    className={styles.amenityIcon}
                  />
                )}
                {amenity.name}
              </li>
            );
          })}
      </ul>
      <div className={styles.showMore}>
        <Button
          layout="link"
          buttonProps={{
            className: styles.showMoreButton,
            title: i18n.rooms.showMore,
            onClick: () => toggleAmenitiesModal(true),
          }}
        >
          {i18n.rooms.showMore}
        </Button>
      </div>
      <AmenitiesModal
        room={room}
        open={showAmenitiesModal}
        onClose={() => toggleAmenitiesModal(false)}
        shareView={shareView}
      />
    </div>
  );
};

export default FavoredAmenities;
