import "@fontsource-variable/inter";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./global.css";

const el = document.getElementById("root");
if (!el) throw Error("#root element not found");

createRoot(el).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
