import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { ReactNode } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { Room } from "../../http/roomApi";
import { translate } from "../../i18n";
import { BackButton } from "../../ui/BackButton";
import RoomDetail from "./RoomDetail";
import styles from "./RoomDetailModal.module.css";

interface RoomDetailModalProps {
  room: Room | null | undefined;
  open: boolean;
  onClose: () => void;
  prices?: ReactNode;
  footer?: ReactNode | (() => ReactNode);
  availableRooms?: number | null;
}

const RoomDetailModal = ({
  room,
  open,
  onClose,
  prices,
  footer,
  availableRooms,
}: RoomDetailModalProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  if (!room) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} className={styles.roomDetailModal}>
      <DialogPanel className={styles.dialog}>
        <div className={styles.innerDialog}>
          <DialogTitle as="div" className={styles.header}>
            {!!availableRooms && (
              <div className={styles.availability}>
                {i18n.rooms.availableRooms(availableRooms)}
              </div>
            )}
            <BackButton onClick={onClose} title={i18n.rooms.backToOverview} />
          </DialogTitle>
          <RoomDetail
            room={room}
            prices={prices}
            footer={typeof footer === "function" ? footer() : footer}
          />
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default RoomDetailModal;
