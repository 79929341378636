import DOMPurify from "dompurify";

export const sanitize = (string: string): TrustedHTML =>
  DOMPurify.sanitize(string, {
    ALLOWED_TAGS: ["p", "ul", "ol", "li", "strong", "em", "a"],
    ALLOWED_ATTR: ["href"],
    RETURN_TRUSTED_TYPE: true,
  });

export const isUUID = (value: string): boolean =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    value,
  );

export const removeAllWhitespace = (value: string): string =>
  value.replaceAll(/\s+/g, "");
