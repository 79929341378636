import { useColorTheme } from "./hooks/useColorTheme";
import Router from "./Router";
import { site } from "./utils/site";

const App = () => {
  useColorTheme(site.colors);

  return (
    <main>
      <Router />
    </main>
  );
};

export default App;
