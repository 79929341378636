import Button, { ButtonProps } from "./Button";

type ShareButtonProps = ButtonProps & {
  shareData: ShareData;
};

const ShareButton = ({ shareData, ...restShareProps }: ShareButtonProps) => {
  // This is required, since not all browsers support the web share api, such as Firefox (only available if enabled in preferences)
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!navigator.canShare?.(shareData)) {
    return null;
  }

  const { buttonProps, ...restButtonProps } = restShareProps;

  const share = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.warn("error while sharing data", err);
    }
  };

  return (
    <Button
      {...restButtonProps}
      buttonProps={{
        ...buttonProps,
        onClick: () => {
          share();
        },
      }}
    />
  );
};

export default ShareButton;
