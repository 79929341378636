import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import classNames from "classnames";
import { useBookingStore } from "../../hooks/useBookingStore";
import { Room } from "../../http/roomApi";
import { translate } from "../../i18n";
import { BackButton } from "../../ui/BackButton";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import { site } from "../../utils/site";
import styles from "./AmenitiesModal.module.css";
import AmenityIcons from "./AmenityIcons";

interface AmenitiesModalProps {
  room: Room;
  open: boolean;
  onClose: () => void;
  shareView?: boolean;
}

const AmenitiesModal = ({
  room,
  open,
  onClose,
  shareView = false,
}: AmenitiesModalProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Dialog open={open} onClose={onClose} className={styles.amenitiesModal}>
      <DialogPanel
        className={classNames(styles.dialog, {
          [styles.shareView]: shareView,
        })}
      >
        <div className={styles.innerDialog}>
          <DialogTitle as="div" className={styles.header}>
            <Headline
              size={2}
              title={i18n.rooms.amenities(site.room_term)}
              as="div"
              className={styles.headerTitle}
            />
            <BackButton onClick={onClose} />
          </DialogTitle>
          <div className={styles.dialogContent}>
            <Headline
              size={2}
              title={i18n.rooms.amenities(site.room_term)}
              as="h1"
              className={styles.headline}
            />
            <CenterWrapper>
              <Headline
                size={4}
                title={room.description}
                as="h2"
                className={styles.description}
              />
              <div className={styles.amenities}>
                {room.amenityGroups.map((group) => (
                  <div key={group.id}>
                    <Headline
                      size={5}
                      title={group.name}
                      as="h3"
                      className={styles.groupHeadline}
                    />
                    <ul className={styles.amenityList}>
                      {group.amenities.map((amenity) => {
                        const AmenityIcon = AmenityIcons[amenity.id];
                        return (
                          <li key={amenity.id} className={styles.amenity}>
                            {AmenityIcon && (
                              <AmenityIcon
                                aria-hidden={true}
                                className={styles.amenityIcon}
                              />
                            )}
                            {amenity.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ))}
              </div>
            </CenterWrapper>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default AmenitiesModal;
