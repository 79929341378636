import { OrderDirection } from "../../@types";
import { Offer } from "../../http/offerApi";
import { Room } from "../../http/roomApi";

export enum OfferRoomSortKey {
  RoomSize,
  OfferPrice,
}

export interface OfferAndRoom {
  offer: Offer;
  room: Room;
}

export interface OfferRoomOrderCriterion {
  direction: OrderDirection;
  sortKey: OfferRoomSortKey;
}

const sortByRoomSize = (elements: OfferAndRoom[], direction: OrderDirection) =>
  elements.sort((a, b) => {
    return direction === OrderDirection.Ascending
      ? (a.room.size ?? 0) - (b.room.size ?? 0)
      : (b.room.size ?? 0) - (a.room.size ?? 0);
  });

const sortByOfferPrice = (
  elements: OfferAndRoom[],
  direction: OrderDirection,
) =>
  elements.sort((a, b) =>
    direction === OrderDirection.Ascending
      ? a.offer.cheapest_prices.min.amount - b.offer.cheapest_prices.min.amount
      : b.offer.cheapest_prices.min.amount - a.offer.cheapest_prices.min.amount,
  );

export const sortOffersWithRooms = (
  elements: OfferAndRoom[],
  orderCriterion: OfferRoomOrderCriterion | undefined,
) => {
  if (!orderCriterion) {
    return elements;
  }

  if (orderCriterion.sortKey === OfferRoomSortKey.RoomSize) {
    return sortByRoomSize(elements, orderCriterion.direction);
  }

  return sortByOfferPrice(elements, orderCriterion.direction);
};
