import {
  addDays,
  differenceInMonths,
  format,
  isAfter,
  isBefore,
  isSameDay,
  Locale,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { createContext, useContext } from "react";
import { CalendarMonth } from "react-day-picker";
import { useFormContext } from "react-hook-form";
import { ArrivalAvailabilityDay } from "../../http/availabilityApi";
import { defaultMonthsCount } from "../../utils/constants";

export interface CalendarContextValue {
  arrival: Date | null;
  arrivalAvailabilitiesMap: Map<number, ArrivalAvailabilityDay>;
  arrivalDays: Date[];
  departure: Date | null;
  departureDays: Date[];
  minStay: number;
  maxStay: number;
  minDepartureDate: Date;
  maxDepartureDate: Date;
  updateMonthRef: (
    ref: HTMLDivElement | null,
    calendarMonth: CalendarMonth,
    index?: number,
  ) => void;
}
export const CalendarContext = createContext<CalendarContextValue | null>(null);

export const useCalenderContext = () => {
  const context = useContext(CalendarContext);
  if (!context) {
    throw new Error(
      "useCalenderContext only allowed inside CalendarContext.Provider!",
    );
  }

  return context;
};

export const getMonthsConfig = (
  arrival: Date | null,
): {
  initialMonthCount: number;
  arrivalMonthOffset: number;
} => {
  const now = new Date();
  if (!arrival) {
    return {
      initialMonthCount: defaultMonthsCount,
      arrivalMonthOffset: 0,
    };
  }

  const arrivalMonthOffset = differenceInMonths(
    startOfMonth(arrival),
    startOfMonth(now),
  );

  const minMonthsRequired = arrivalMonthOffset + 3;

  return {
    arrivalMonthOffset,
    initialMonthCount:
      minMonthsRequired > defaultMonthsCount
        ? minMonthsRequired
        : defaultMonthsCount,
  };
};

export enum InvalidDayReasonType {
  None,
  DepartureBeforeArrival,
  MinStay,
  MaxStay,
  ArrivalNotAvailable,
  DepartureNotAvailable,
}

export interface InvalidDayReason {
  type: InvalidDayReasonType;
  value?: number;
}

interface IsDayInvalidArguments {
  arrival: Date | null;
  departure: Date | null;
  arrivalDays: Date[];
  departureDays: Date[];
  minStay: number;
  maxStay: number;
  minDepartureDate: Date;
  maxDepartureDate: Date;
}
export const isDayInvalid = (
  day: Date,
  {
    arrival,
    departure,
    arrivalDays,
    departureDays,
    minStay,
    maxStay,
    minDepartureDate,
    maxDepartureDate,
  }: IsDayInvalidArguments,
): false | InvalidDayReason => {
  if (!arrival || departure) {
    const arrivalPossible = arrivalDays.some((ad) => isSameDay(day, ad));
    if (arrivalPossible) {
      return false;
    }

    return {
      type: InvalidDayReasonType.ArrivalNotAvailable,
    };
  }

  if (isSameDay(day, arrival)) {
    return false;
  }

  if (isBefore(day, arrival)) {
    return {
      type: InvalidDayReasonType.DepartureBeforeArrival,
    };
  }

  if (isBefore(day, minDepartureDate)) {
    return {
      type: InvalidDayReasonType.MinStay,
      value: minStay,
    };
  }

  if (isAfter(day, maxDepartureDate)) {
    return {
      type: InvalidDayReasonType.MaxStay,
      value: maxStay,
    };
  }

  if (departureDays.every((dd) => !isSameDay(day, dd))) {
    return {
      type: InvalidDayReasonType.DepartureNotAvailable,
    };
  }

  return false;
};

export interface FormDataCalendar {
  arrival: Date | null;
  departure: Date | null;
}

export const useCalendarFormContext = () => useFormContext<FormDataCalendar>();

export const getWeekdays = (locale: Locale): string[] => {
  const start = startOfWeek(new Date(), { locale });
  return [...Array(7)].map((_, i) =>
    format(addDays(start, i), "cccccc", { locale }),
  );
};
