import { RoomTerm } from "../@types";

export const getRoomTerm = ({
  term,
  room,
  apartment,
  suite,
}: {
  term: RoomTerm;
  room: string;
  apartment: string;
  suite: string;
}) =>
  ({
    [RoomTerm.Room]: room,
    [RoomTerm.Apartment]: apartment,
    [RoomTerm.Suite]: suite,
  })[term];
