import { FunctionComponent, SVGProps } from "react";
import Airwave from "../../ui/icon/airwave.svg?react";
import Apartment from "../../ui/icon/apartment.svg?react";
import Badge from "../../ui/icon/badge.svg?react";
import Balcony from "../../ui/icon/balcony.svg?react";
import BarrierFree from "../../ui/icon/barrier-free.svg?react";
import Bathroom from "../../ui/icon/bathroom.svg?react";
import Bathtub from "../../ui/icon/bathtub.svg?react";
import Beach from "../../ui/icon/beach.svg?react";
import Bed from "../../ui/icon/bed.svg?react";
import BunkBed from "../../ui/icon/bunk-bed.svg?react";
import Cafe from "../../ui/icon/cafe.svg?react";
import CastConnected from "../../ui/icon/cast-connected.svg?react";
import CommonKitchen from "../../ui/icon/common-kitchen.svg?react";
import Deck from "../../ui/icon/deck.svg?react";
import DietaryCuisine from "../../ui/icon/dietary-cuisine.svg?react";
import Dining from "../../ui/icon/dining.svg?react";
import DoubleSofa from "../../ui/icon/double-sofa.svg?react";
import Drink from "../../ui/icon/drink.svg?react";
import Fireplace from "../../ui/icon/fireplace.svg?react";
import Flatware from "../../ui/icon/flatware.svg?react";
import Garden from "../../ui/icon/garden.svg?react";
import GasBurner from "../../ui/icon/gas-burner.svg?react";
import HairDryer from "../../ui/icon/hair-dryer.svg?react";
import Home from "../../ui/icon/home.svg?react";
import InternetPoint from "../../ui/icon/internet-point.svg?react";
import Kettle from "../../ui/icon/kettle.svg?react";
import LaundryService from "../../ui/icon/laundry-service.svg?react";
import Microwave from "../../ui/icon/microwave.svg?react";
import Mop from "../../ui/icon/mop.svg?react";
import Mountain from "../../ui/icon/mountain.svg?react";
import MovingBed from "../../ui/icon/moving-bed.svg?react";
import Multicooker from "../../ui/icon/multicooker.svg?react";
import Receipt from "../../ui/icon/receipt.svg?react";
import Restaurant from "../../ui/icon/restaurant.svg?react";
import Safe from "../../ui/icon/safe.svg?react";
import Scene from "../../ui/icon/scene.svg?react";
import Shower from "../../ui/icon/shower.svg?react";
import Sofa from "../../ui/icon/sofa.svg?react";
import Solarium from "../../ui/icon/solarium.svg?react";
import SoundDetection from "../../ui/icon/sound-detection.svg?react";
import SpaLicence from "../../ui/icon/spa-licence.svg?react";
import Spray from "../../ui/icon/spray.svg?react";
import Stove from "../../ui/icon/stove.svg?react";
import TShirt from "../../ui/icon/t-shirt.svg?react";
import Toilet from "../../ui/icon/toilet.svg?react";
import TV from "../../ui/icon/tv.svg?react";
import Vacuum from "../../ui/icon/vacuum.svg?react";
import Water from "../../ui/icon/water.svg?react";
import WC from "../../ui/icon/wc.svg?react";
import WiFi from "../../ui/icon/wifi.svg?react";

const AmenityIcons: Record<
  string,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  "d7e26c66-f69e-4c06-95b5-5c55d1f30662": Airwave,
  "8b93cdaa-a46f-4d60-88c7-5fc57d9e19f7": Apartment,
  "3d47dd73-5ecc-47f1-ab83-26b831ad9799": Badge,
  "84237b26-d63d-4cba-9986-0a637520def1": Balcony,
  "5c70d228-8e81-428f-9f74-edd5ffb97055": BarrierFree,
  "ce98b3e7-23b9-464e-af6d-9d86243ea7ae": Bathroom,
  "966ddc5d-5340-44af-bba4-60a523149899": Bathtub,
  "89eb90ba-fb87-41ac-a0aa-e38813690c08": Beach,
  "4cfeaec4-8534-4cd6-bd2c-1968f2bd354e": Bed,
  "abde0743-2234-4fe7-8c6c-388a2eaefcd3": BunkBed,
  "15b5275b-7ef5-4606-809d-41ad494e65a6": Cafe,
  "d2087865-6c65-48ce-85f3-1ff0129d59e4": CastConnected,
  "379f16e7-0f9b-49c9-88e1-c195eab30130": CommonKitchen,
  "10a001d2-a0ba-479c-9d17-fea1d769af58": Deck,
  "b58114c4-5cbb-49bb-841e-be9b04af74da": DietaryCuisine,
  "c8082139-5192-4d58-96fb-e66517959b8c": Dining,
  "27babcf3-97d3-437c-b2b3-d0ccdb9c8170": DoubleSofa,
  "19b7403d-bcc4-4bbe-84e5-d6552e8c0d6b": Drink,
  "cfc7ddcd-f6c6-4698-a96a-f78312877762": Fireplace,
  "3a755c6f-9e0c-4c0f-ac0a-890dcdeea90f": Flatware,
  "b384b03f-e8d1-4e7f-be6e-5630cf52fba7": Garden,
  "88bf91db-6813-4025-8a28-35d544a38aa7": GasBurner,
  "be14ea6d-d204-4738-9f9c-89d743606f99": HairDryer,
  "96d1f617-ed7d-4384-aacb-7fe6ff8854a8": Home,
  "65ac0c0c-78e4-4415-b390-42689183b57d": InternetPoint,
  "8d81ce18-fed0-42ca-815f-289b8add8996": Kettle,
  "45de249b-89cf-4a98-9e75-95645e968739": LaundryService,
  "50c2f566-77f8-4221-bec7-07049b92dfa0": Microwave,
  "af6f856a-e574-45f4-aa57-2c6758b348c0": Mop,
  "09138bc2-e624-4c29-97ab-eec3f30ec78b": Mountain,
  "5b03428c-a2df-4001-b6a0-38d07fa13b17": MovingBed,
  "c90e3673-1aa9-4c40-b44a-856cc61b21e8": Multicooker,
  "e5e0626d-336b-436e-ae74-66bdc1a97e3a": Receipt,
  "31844bd4-1990-4ee1-935b-25e1111929dd": Restaurant,
  "78cb5bb8-c853-4288-bce9-ec3924c8c127": Safe,
  "41c651ed-67ba-4c37-8a62-12430800486b": Scene,
  "f9d14dde-8f28-421d-97e7-254925d1bcbe": Shower,
  "f40bcc2c-53e0-4eb3-9694-e888a9d062f6": Sofa,
  "3678cdc9-c793-4a95-ab76-64064bb13077": Solarium,
  "2336c862-73ff-4201-b94e-6911dc66b0a6": SoundDetection,
  "48dcb6ba-6960-4d5f-bf32-2be9619ee7fa": SpaLicence,
  "25c2cd88-85f5-4e41-9dcd-3e608a8b7e10": Spray,
  "6b33667a-3a7a-49fd-a5b4-8634e274018f": Stove,
  "4bae430f-e94a-4d9a-860c-9368d2664ec5": Toilet,
  "74eaad0d-43dd-47a0-b0d3-ba9009802a81": TShirt,
  "ccf685de-ef18-478a-93a0-c6138fbb68a3": TV,
  "8a964130-639f-4c72-acc4-c8e5864fbf28": Vacuum,
  "a0e559f2-815a-4d4a-ba69-3b95a10355b7": Water,
  "70844e27-17dc-4db9-8be4-f3d6f813ee58": WC,
  "eb22fef3-5b33-47d1-8da7-9a4f6b46f36e": WiFi,
};

export default AmenityIcons;
