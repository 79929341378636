import { useState } from "react";
import { BookingStep, RoomConfiguration } from "../../@types";
import {
  selectCurrentRoomConfiguration,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { Offer, useOffers } from "../../http/offerApi";
import { Room as RoomType, useRoomTypes } from "../../http/roomApi";
import { translate } from "../../i18n";
import PageWithHeader from "../../pages/PageWithHeader";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import LightGallery from "../../ui/LightGallery";
import Prices from "../../ui/Prices";
import ResponsiveImage from "../../ui/ResponsiveImage";
import Slider from "../../ui/Slider";
import { site } from "../../utils/site";
import PortalPrices from "./PortalPrices";
import RoomInfos from "./RoomInfos";
import styles from "./Rooms.module.css";
import RoomsSort from "./RoomsSort";
import { OfferRoomOrderCriterion, sortOffersWithRooms } from "./utils";

const Rooms = () => {
  const language = useBookingStore((state) => state.language);
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const currentRoomConfiguration = useBookingStore(
    selectCurrentRoomConfiguration,
  );
  const roomConfigurations = useBookingStore(
    (state) => state.roomConfigurations,
  );
  const i18n = translate(language);
  const { offers } = useOffers();
  const { roomTypesMap } = useRoomTypes();
  const [orderCriterion, setOrderCriterion] =
    useState<OfferRoomOrderCriterion>();
  const setStep = useBookingStoreSetStep();

  if (currentRoomConfiguration?.roomTypeId) {
    setStep(BookingStep.RatePlans, true);
    return null;
  }

  const offersAndRooms = sortOffersWithRooms(
    offers
      ?.filter(
        (offer) =>
          offer.room_index === currentRoomConfiguration?.roomIndex &&
          (!roomTypeId ||
            offer.room_type_id === roomTypeId ||
            roomConfigurations.some(
              (roomConfiguration) =>
                roomConfiguration.roomTypeId === offer.room_type_id,
            )),
      )
      .map((offer) => {
        const room = roomTypesMap?.[offer.room_type_id];

        return room
          ? {
              offer,
              room,
            }
          : null;
      })
      .filter((x) => !!x) ?? [],
    orderCriterion,
  );

  return (
    <PageWithHeader
      header={
        <>
          <Headline
            as="h1"
            size={2}
            title={i18n.rooms.selectRoom[site.guest_interaction](
              site.room_term,
            )}
          />
          <RoomsSort
            orderCriterion={orderCriterion}
            onSort={setOrderCriterion}
          />
        </>
      }
    >
      <CenterWrapper>
        {currentRoomConfiguration && (
          <CurrentRoomConfiguration
            roomConfiguration={currentRoomConfiguration}
          />
        )}
        <PortalPrices />
        <div className={styles.rooms}>
          {offersAndRooms.map((offerAndRoom, index) => (
            <Room
              key={index}
              room={offerAndRoom.room}
              offer={offerAndRoom.offer}
            />
          ))}
        </div>
      </CenterWrapper>
    </PageWithHeader>
  );
};

interface RoomProps {
  room: RoomType | undefined;
  offer: Offer;
}

const Room = ({ room, offer }: RoomProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const setStep = useBookingStoreSetStep();

  if (!room) {
    return null;
  }

  const toggleDetailView = () => {
    updateRoomConfiguration({ roomTypeId: room.id });
    setStep(BookingStep.RatePlans);
  };

  return (
    <Card
      header={<RoomHeader room={room} availableRooms={offer.available_rooms} />}
      footer={
        <Button
          buttonProps={{
            title: i18n.rooms.continue,
            onClick: () => toggleDetailView(),
          }}
        >
          {i18n.rooms.continue}
        </Button>
      }
    >
      <Button
        layout="link"
        buttonProps={{
          title: room.name,
          onClick: () => toggleDetailView(),
          className: styles.roomName,
        }}
      >
        {room.name}
      </Button>
      <RoomInfos
        room={room}
        prices={
          <Prices
            basePrice={offer.cheapest_prices.base}
            minPrice={offer.cheapest_prices.min}
            minPricePerNight={offer.cheapest_prices.min_per_night}
            discount={offer.cheapest_prices.discount}
          />
        }
      />
    </Card>
  );
};

interface RoomHeaderProps {
  room: RoomType;
  availableRooms: number | null;
}

const RoomHeader = ({ room, availableRooms }: RoomHeaderProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [showRoomPhotos, setShowRoomPhotos] = useState(false);

  return (
    <div className={styles.roomHeader}>
      {!!availableRooms && (
        <div className={styles.availability}>
          {i18n.rooms.availableRooms(availableRooms)}
        </div>
      )}
      <LightGallery
        photos={room.photos}
        isOpen={showRoomPhotos}
        onClose={() => setShowRoomPhotos(false)}
      />
      <Slider dots={room.photos.length > 1} className={styles.roomHeaderSlider}>
        {room.photos.map((photo, index) => (
          <a
            className={styles.slide}
            aria-label={i18n.general.openLightbox}
            key={index}
            href={photo.derivatives[0]?.url}
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              setShowRoomPhotos(true);
            }}
          >
            <ResponsiveImage
              srcSet={photo.derivatives}
              alt={photo.description}
              sizes="(min-width: 921px) 33vw, (min-width: 614px) 50vw, 100vw"
              className={styles.sliderImage}
              lazyLoad={true}
            />
          </a>
        ))}
      </Slider>
    </div>
  );
};

interface CurrentRoomConfigurationProps {
  roomConfiguration: RoomConfiguration;
}

const CurrentRoomConfiguration = ({
  roomConfiguration,
}: CurrentRoomConfigurationProps) => {
  const { adults = 0, children = [], roomIndex = 0 } = roomConfiguration;
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const visualIndex = roomIndex + 1;

  return (
    <div className={styles.currentConfiguration}>
      <Headline size={3} title={i18n.rooms.room(site.room_term, visualIndex)} />
      <div>
        {adults && i18n.roomConfigurations.adults(adults)}
        {children.length > 0 &&
          `, ${i18n.roomConfigurations.children(children.length)}`}
      </div>
    </div>
  );
};

export default Rooms;
