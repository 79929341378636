import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import { sanitize } from "../utils/string";
import Button from "./Button";
import styles from "./ReadMore.module.css";

interface ReadMoreProps {
  text: string;
  className?: string;
}

export const ReadMore = ({ text, className }: ReadMoreProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const elementRef = useRef<HTMLDivElement>(null);
  const [isClamped, setClamped] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    const observeTarget = elementRef.current;
    const observer = new ResizeObserver(() => {
      if (observeTarget) {
        setClamped(observeTarget.scrollHeight > observeTarget.clientHeight);
      }
    });

    if (observeTarget) {
      observer.observe(observeTarget);
    }

    return () => {
      if (observeTarget) {
        observer.unobserve(observeTarget);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div
        ref={elementRef}
        className={classNames(className, styles.readMore, {
          [styles.expanded]: isExpanded,
        })}
        dangerouslySetInnerHTML={{
          __html: sanitize(text),
        }}
      />
      {isClamped && !isExpanded && (
        <Button
          layout="link"
          buttonProps={{
            className: styles.button,
            title: i18n.general.readMore,
            onClick: () => setExpanded(!isExpanded),
          }}
        >
          {i18n.general.readMore}
        </Button>
      )}
    </>
  );
};
