import { FieldArrayWithId } from "react-hook-form";
import OccupancyRoom from "./OccupancyRoom";
import styles from "./OccupancyRooms.module.css";
import { FormDataOccupancies } from "./utils";

interface OccupancyRoomsProps {
  fields: FieldArrayWithId<FormDataOccupancies, "occupancies">[];
  openedRoom: number;
  onOpen: (index: number) => void;
  onDelete: (index: number) => void;
}

const OccupancyRooms = ({
  fields,
  openedRoom,
  onOpen,
  onDelete,
}: OccupancyRoomsProps) => {
  return (
    <div className={styles.rooms}>
      {fields.map((occupancy, index) => (
        <OccupancyRoom
          key={occupancy.id}
          index={index}
          open={index === openedRoom}
          onOpen={onOpen}
          onDelete={() => {
            onDelete(index);
          }}
        />
      ))}
    </div>
  );
};

export default OccupancyRooms;
