import { useBookingStore } from "../../hooks/useBookingStore";
import Headline from "../../ui/Headline";
import { getLocaleByLanguage } from "../../utils/language";
import styles from "./CalendarHeader.module.css";
import { getWeekdays } from "./utils";

const CalendarHeader = () => {
  const language = useBookingStore((state) => state.language);

  return (
    <div className={styles.header}>
      {getWeekdays(getLocaleByLanguage(language)).map((weekday) => (
        <Headline
          className={styles.day}
          size={7}
          title={weekday}
          key={weekday}
        />
      ))}
    </div>
  );
};

export default CalendarHeader;
